<template>
  <div>
    <component :is="gameViewer"></component>
  </div>
</template>

<script>
import { isMobile } from "@/libs/utils";

export default {
  name: "gameFrame",
  props:['device','gameName'],
  data() {
    return {
      gameViewer: null
    };
  },
  methods: {
    loadComponent(gameName, device) {
      if(['dhpowerball','dhladder'].includes(gameName))  {
        alert('중계화면을 불러오는데 실패했습니다. 다시 접속해 주십시오.');
        return
      }
      import(`./${gameName}${device === 'mobile'?'_m':''}`)
          .then(
              component => {
                this.gameViewer = component.default || component
              }
          )
          .catch(error => {
        console.error(`Failed to load component: ${error}`);
        alert('중계화면을 불러오는데 실패했습니다. 다시 접속해 주십시오.');
      });
    },
    init() {
      let gameName = this.gameName
      if(!gameName) {
        this.$router.push('/')
      }
      let device = this.device
      if(!device) {
        device = isMobile() ? 'mobile' : 'pc'
      }
      this.loadComponent(gameName, device)
    }
  },
  created() {
    this.init()
  },
};
</script>

<style scoped></style>
